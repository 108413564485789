
.preview {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
}

.file-box{
  /* border: 0.5px solid */
}
  .file-container{
  display: flex;
  align-items: center;
  gap: 16px;
}

.file-text-style {
  font-weight: 400;
  font-size: 12px !important;
}

.MuiAvatar-img{
  width: 95px;
  height: 95px;
}