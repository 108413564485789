*,
*:after,
*:before {
  box-sizing: border-box;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.hover-text-one {
  overflow: hidden;
  margin: 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.hover-text-one figure {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  /* background: #527c82; */
  text-align: center;
  cursor: pointer;
}
.hover-text-one figure img {
  position: relative;
  display: block;
  min-height: 100%;
  opacity: 0.8;
}
.hover-text-one figure figcaption {
  padding: 2em;
  color: #fff;
  /* text-transform: uppercase; */
  font-size: 16px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.hover-text-one figure figcaption::before,
.hover-text-one figure figcaption::after {
  pointer-events: none;
}
.hover-text-one figure figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hover-text-one figure figcaption::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  z-index: -1;
  transition: opacity 0.4s linear;
  opacity: 0;
}

.hover-text-one figure figcaption:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hover-text-one figure h3 {
  word-spacing: -0.15em;
  font-weight: 300;
  /* font-size: 68.5%; */
}
.hover-text-one figure h3 span {
  font-weight: 800;
}
.hover-text-one figure h3,
.hover-text-one figure p {
  margin: 0;
}

.hover-text-one figure p {
  letter-spacing: 1px;
}

figure {
  position: relative;
}

/* Эффект при наведении */
figure.effect-text-three {
  /* background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%); */
}
figure.effect-text-three img {
  width: -webkit-calc(100%);
  width: calc(100%);
  -webkit-transition: 0.4s, -webkit-transform 0.6s;
  transition: 0.4s, transform 0.6s;
  /* transform: scale(1.1); */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
figure.effect-text-three:hover img {
  /* opacity: 0.6; */
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  filter: brightness(60%);
}
figure.effect-text-three figcaption {
  text-align: left;
}

/* figure.effect-text-three:hover figcaption {
  background-color: black;
  opacity: 0.5;
} */

figure.effect-text-three h3 {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 30px;

  display: block;
  width: 100%;
  transition: all 0.6s ease;
}
figure.effect-text-three:hover h3 {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  transition: all 0.6s ease;
}
figure.effect-text-three p {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px 30px;
  display: block;
  width: 100%;
  transition: all 0.6s ease;
}
figure.effect-text-three:hover p {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: all 0.6s ease;
}
figcaption:hover > div {
  opacity: 1;
}
