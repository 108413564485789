@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Bold.eot");
  src: local("Gotham Pro Bold"), local("GothamPro-Bold"),
    url("GothamPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-Bold.woff2") format("woff2"),
    url("GothamPro-Bold.woff") format("woff"),
    url("GothamPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Light.eot");
  src: local("Gotham Pro Light"), local("GothamPro-Light"),
    url("GothamPro-Light.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-Light.woff2") format("woff2"),
    url("GothamPro-Light.woff") format("woff"),
    url("GothamPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Black.eot");
  src: local("Gotham Pro Black"), local("GothamPro-Black"),
    url("GothamPro-Black.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-Black.woff2") format("woff2"),
    url("GothamPro-Black.woff") format("woff"),
    url("GothamPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Italic.eot");
  src: local("Gotham Pro Italic"), local("GothamPro-Italic"),
    url("GothamPro-Italic.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-Italic.woff2") format("woff2"),
    url("GothamPro-Italic.woff") format("woff"),
    url("GothamPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro Narrow";
  src: url("GothamProNarrow-Bold.eot");
  src: local("Gotham Pro Narrow Bold"), local("GothamProNarrow-Bold"),
    url("GothamProNarrow-Bold.eot?#iefix") format("embedded-opentype"),
    url("GothamProNarrow-Bold.woff2") format("woff2"),
    url("GothamProNarrow-Bold.woff") format("woff"),
    url("GothamProNarrow-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Medium.eot");
  src: local("Gotham Pro Medium"), local("GothamPro-Medium"),
    url("GothamPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-Medium.woff2") format("woff2"),
    url("GothamPro-Medium.woff") format("woff"),
    url("GothamPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-BoldItalic.eot");
  src: local("Gotham Pro Bold Italic"), local("GothamPro-BoldItalic"),
    url("GothamPro-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-BoldItalic.woff2") format("woff2"),
    url("GothamPro-BoldItalic.woff") format("woff"),
    url("GothamPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro.eot");
  src: local("Gotham Pro"), local("GothamPro"),
    url("GothamPro.eot?#iefix") format("embedded-opentype"),
    url("GothamPro.woff2") format("woff2"), url("GothamPro.woff") format("woff"),
    url("GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-MediumItalic.eot");
  src: local("Gotham Pro Medium Italic"), local("GothamPro-MediumItalic"),
    url("GothamPro-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-MediumItalic.woff2") format("woff2"),
    url("GothamPro-MediumItalic.woff") format("woff"),
    url("GothamPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-BlackItalic.eot");
  src: local("Gotham Pro Black Italic"), local("GothamPro-BlackItalic"),
    url("GothamPro-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-BlackItalic.woff2") format("woff2"),
    url("GothamPro-BlackItalic.woff") format("woff"),
    url("GothamPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro Narrow";
  src: url("GothamProNarrow-Medium.eot");
  src: local("Gotham Pro Narrow Medium"), local("GothamProNarrow-Medium"),
    url("GothamProNarrow-Medium.eot?#iefix") format("embedded-opentype"),
    url("GothamProNarrow-Medium.woff2") format("woff2"),
    url("GothamProNarrow-Medium.woff") format("woff"),
    url("GothamProNarrow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-LightItalic.eot");
  src: local("Gotham Pro Light Italic"), local("GothamPro-LightItalic"),
    url("GothamPro-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("GothamPro-LightItalic.woff2") format("woff2"),
    url("GothamPro-LightItalic.woff") format("woff"),
    url("GothamPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

.rdw-link-modal {
  /* background-color: red; */
}
.rdw-link-modal-target-option {
  background-color: blue;
  display: none;
}
