* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

iframe {
  pointer-events: none;
}

.post {
  display: flex;
  padding: 15px;
  border: 2px solid teal;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}

.post__btns {
  display: flex;
}

.post-enter {
  transform: translateX(-350px);
}

.post-enter-active {
  transform: translateX(0px);

  transition: all 500ms ease-in;
}

.post-exit {
  opacity: 1;
}

.post-exit-active {
  transform: translateX(-350px);
  transition: all 500ms ease-in;
}

.page__wrapper {
  margin-top: 30px;
  display: flex;
}

.page {
  padding: 10px;
  border: 1px solid teal;
  cursor: pointer;
}

.page__current {
  border: 2px solid orange;
  font-weight: bold;
}
.empty-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
