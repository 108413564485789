.lineClamp__1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__default__header {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__default__description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__cardHeader {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__cardShortDesc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__newsOnLanding {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__newsOnLanding_desc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp__newsOnLanding_mobile {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.unicode:after {
  content: '\20BD';
}

/*_______________________Прокрутка горизонтального меню (Календарь, Категории)  */

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.scroller__items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  /* cursor: pointer; */
  /* padding-bottom: 10px; */
}

.scroller__items::-webkit-scrollbar {
  height: 6px;
  background-color: #f9f9fd;
}

.scroller__items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ff5631;
}

.scroller__items::-webkit-scrollbar-thumb:hover {
  background-color: #df4c2b;
}

.scroller__items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

.scroller__items__noneScrollbar {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.scroller__items__noneScrollbar::-webkit-scrollbar {
  display: none;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.scrolling-wrapper::-webkit-scrollbar {
  display: block;
  width: 12px;
  margin-top: 10px;
}

@media (max-width: 960px) {
  .scrolling-wrapper::-webkit-scrollbar {
    display: none;
  }
}
.card {
  flex: 0 0 auto;
}

/*__________выбор даты при создании мероприятия*/
.react-datetime-picker__wrapper {
  padding: 5.5px 0px;
  border-radius: 4px;
}

.react-datetime-picker__inputGroup__leadingZero {
  font-size: 17px;
}
